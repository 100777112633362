
import { ImagePreview } from 'vant';

export default {

    created() {

        let t = this;

        if (t.U.id)
            mirror(t.U, t.f);

        if (!t.f.gender)
            t.f.gender = 1;


        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;

            t.list.unit = [
                { id: null, name: '无' },
            ];

            axios.get('unit').then(res => {
                if (res.data.status == 1) {
                    t.list.unit.append(res.data.options.items);
                }
            });

            mirror(getFields(t.U, ['unit_text', 'desc']), t.f);

            if (t.U.img_urls) {
                let imgUrls = t.U.img_urls.split(',');

                imgUrls.forEach(src => {
                    t.list.img.push({
                        src: src,
                        showActions: false
                    });
                })
            }

        },

        onSelectUnit(unit) {
            this.selectedUnit = unit;
            this.showUnitSelector = false;
            if (unit.id)
                this.f.unit_text = unit.name;
            else
                this.f.unit_text = '';
        },

        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 600,
                minWdith: 600,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#333',
            }).toBlob((blob) => {

                let blobUrl = getObjectURL(blob);
                t.mainImgBlobDict[blobUrl] = blob;
                t.list.img.push({
                    waitting_upload: 1,
                    src: blobUrl,
                    showActions: false
                });
                t.closeCropper();
            });
        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 3 / 4,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },

        save() {

            let t = this;

            let f = t.f;

            if (!f.name) {
                A.err('请输入姓名');
                return;
            }

            if (!f.age) {
                A.err('请输入年龄');
                return;
            }

            if (!f.desc) {
                A.err('请输入咨询目的');
                return;
            }

            if (t.busying)
                return;

            function _save() {

                let form = getFields(f, ['desc', 'gender', 'name', 'age', 'weight', 'height']);

                let imgUrls = [];

                t.list.img.forEach((img) => {
                    imgUrls.push(img.src);
                });

                form.img_urls = imgUrls.join(',');

                t.busying = true;

                axios.post('user/saveProfile', form).then(res => {
                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('就诊资料已保存');
                        mirror(res.data.options.item, t.U);
                        S.set('_u', t.U);

                        if (B.medicalPage && B.medicalPage.nextPage)
                            t.$router.replace(B.medicalPage.nextPage);
                        else
                            t.back();
                    }
                });

            }

            let idx = 0;

            let fd = new FormData();

            let imgIndexs = [];

            t.list.img.forEach((img, i) => {
                if (img.waitting_upload == 1) {
                    let blobUrl = img.src;
                    imgIndexs.push(i);
                    fd.append('image_' + idx, t.mainImgBlobDict[blobUrl]);
                    idx++;
                }
            });

            if (idx > 0) {

                fd.append('qty', idx.toString());

                t.busying = true;

                axios.post('user/saveMedicalImages', fd, { headers: { 'Content-Type': 'multipart/form-data' } }).then(res => {
                    if (res.data.status == 1) {
                        res.data.options.img_urls.forEach((url, i) => {
                            delete t.list.img[imgIndexs[i]].waitting_upload;
                            t.list.img[imgIndexs[i]].src = url;
                        });
                        _save();
                    }
                });

            }
            else
                _save();

        },

        skip() {
            localStorage['skip_medical'] = 1;
            if (B.medicalPage && B.medicalPage.nextPage)
                this.$router.replace(B.medicalPage.nextPage);
            else
                this.$router.replace('/user');
        },

        showPreview(idx) {

            let t = this;

            if (t.list.img.length > 0) {
                let srcArr = [];

                t.list.img.forEach(img => {
                    srcArr.push(t.url(img.src));
                });

                ImagePreview({
                    images: srcArr,
                    startPosition: idx,
                    closeable: true,
                });
            }


        }
    },
    data() {
        return {
            selectedUnit: null,
            showUnitSelector: false,
            f: {
                name: '',
                age: '',
                gender: 1,
                desc: ''
            },
            list: {
                img: [],
                unit: []
            },
            mainImgBlobDict: {},

            cropper: null,

        };
    },
};
