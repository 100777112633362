
export default {
    name: 'duty',
    created() {

        let t = this;

        t.loadData();

        new ClipboardJS('button[data-clipboard-text]');

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {

            let t = this;

            axios.get('user/chat?m=duty').then(res => {
                if (res.data.status == 1) {
                    t.list.chat = res.data.options.items;

                    t.stats.total = t.list.chat.length;

                    t.list.chat.forEach(chat => {

                        if (chat.last_sent_by == 1 && chat.duty_flag != 1) {
                            t.stats.waiting++;
                            chat.text = chat.other.name + '专家，咨询者[' + chat.user.name + ']已等候' + t.time_diff(chat.latest_msg.created_at) + '，请及时回复。';
                        }

                    });
                }
            });

        },

        goChat(item) {
            this.$router.push('/dchat?cid=' + item.id)
        },

        ignore(item) {

            let t = this;

            t.list.chat.remove(item);

            axios.post('user/chat/ignore/' + item.id).then(res => {
                if (res.data.status == 1) {
                    t.stats.waiting--;
                }
            });

        },

        copy(item) {
            let t = this;
            A.toast('复制成功');
        }

    },
    data() {
        return {
            search: {
                status: 'all',
                keywords: ''
            },
            stats: {
                total: 0,
                waiting: 0
            },
            list: {
                chat: [
                ],
                group: []
            },

        };
    },
    computed: {
        filteredChats: function () {
            var arr = [];
            var arr2 = this.list.chat;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.status != 'all' && item.last_sent_by != 1)
                    continue;
                if (this.search.status != 'all' && item.duty_flag == 1)
                    continue;
                if (this.search.keywords && item.other.name.indexOf(this.search.keywords) < 0 && item.user.name.indexOf(this.search.keywords) < 0)
                    continue;

                arr.push(item);
            }

            return arr;
        }
    }
};
