
export default {
    name: 'echats',
    created() {

        let t = this;

        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {

            let t = this;

            axios.get('user/chat?m=expert').then(res => {
                if (res.data.status == 1) {
                    t.list.chat = res.data.options.items;

                    t.stats.total = t.list.chat.length;

                    t.list.chat.forEach(chat => {

                        if (chat.count_unread > 0)
                            t.stats.unread++;

                    });

                    t.list.group = res.data.options.groups;

                    t.stats.group = t.list.group.length;
                }
            });

        },

        goChat(item) {
            if (item.count_unread > 0) {
                this.stats.unread--;
                item.count_unread = 0;
            }
            this.$router.push('/chat?oid=' + item.other.id)
        },

        goGroup(group){
            this.$router.push('/egroup?chat_id=' + group.id);
        }
    },
    data() {
        return {
            search: {
                status: 'group',
                keywords:''
            },
            stats: {
                group:0,
                total: 0,
                unread: 0
            },
            list: {
                chat: [
                ],
                group:[]
            },

        };
    },
    computed: {
        filteredChats: function () {
            var arr = [];
            var arr2 = this.list.chat;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.status != 'all' && item.count_unread < 1)
                    continue;
                if (this.search.keywords && item.other.name.indexOf(this.search.keywords) < 0)
                    continue;
                arr.push(item);
            }

            return arr;
        },
        filteredGroups: function () {
            var arr = [];
            var arr2 = this.list.group;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.keywords && item.user.name.indexOf(this.search.keywords) < 0)
                    continue;
                arr.push(item);
            }

            return arr;
        }
    }
};
