
import Vue from 'vue'

export default {

    created() {

        let t = this;

        if (!t.U.name) {
            t.$router.replace('/eprofile');
            return;
        }

        t.loadData();
    },
    activated() {
        let t = this;
        t.U = S.get('_u');
        t.$forceUpdate();
    },
    methods: {

        loadData() {
            let t = this;

            axios.get('user/getStats').then(res => {
                if (res.data.status == 1) {
                    t.stats = res.data.options;
                }
            });

        },

        editProfile() {
            this.$router.replace('/eprofile?back=1');
        },

        userMode() {
            localStorage['override_mode'] = 1;
            Vue.prototype.mode = 1;
            this.$router.replace('/user');
            this.refreshNav();
        }
    },
    data() {
        return {
            stats: {
                count_chat: 0,
                count_article: 0,
            }
        };
    },
};
