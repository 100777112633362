
export default {

    created() {

        let t = this;


        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;

            axios.get('article?_with=author').then(res => {
                if (res.data.status == 1) {
                    t.list.article = res.data.options.items;
                }
            });

        },

        goArticle(item) {
            this.$router.push('/article?id=' + item.id)
        }
    },
    data() {
        return {
            search: {
                keywords: ''
            },
            list: {
                article: [
                ],
            },

        };
    },
    computed: {
        filteredArticles: function () {
            var arr = [];
            var arr2 = this.list.article;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.keywords && item.title.indexOf(this.search.keywords) < 0)
                    continue;
                arr.push(item);
            }

            return arr;
        }
    }
};
