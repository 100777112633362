
import Vue from 'vue'
export default {

    created() {

        let t = this;

        t.loadData();

    },
    activated() {
        let t = this;
        t.U = S.get('_u');
        t.$forceUpdate();
    },
    methods: {

        loadData() {
            let t = this;
        },

        editProfile() {
            this.$router.push('/profile?back=1');
        },

        expertMode() {
            localStorage['override_mode'] = 2;
            Vue.prototype.mode = 2;
            this.$router.replace('/euser');
            this.refreshNav();
        },

        clearCache() {
            let openId = this.U.open_id;
            localStorage.clear();
            location.replace('/?open_id=' + openId);
        }
    },
    data() {
        return {

        };
    },
};
