

export default {

    created() {

    },
    activated() {
        let t = this;

        t.loadData();
    },
    methods: {

        loadData() {
            let t = this;

            let p = getURLParams();

            if (p.id) {
                axios.get('article/' + p.id).then(res => {
                    if (res.data.status == 1) {
                        t.article = res.data.options.item;
                        if (!t.article)
                            t.$router.replace(t.mode == 2 ? '/earticles' : '/articles');
                    }
                });
            }
            else
                t.$router.replace(t.mode == 2 ? '/earticles' : '/articles');
        },

        goExpert(expert_id) {
            if (this.U.id) { $router.push('/expert?id=' + expert_id); }
        },

        editArticle() {
            this.$router.replace('/newarticle?id=' + this.article.id);
        },

        deleteArticle() {
            let t = this;
            A.safety('确定要删除该文章吗？').then(res => {
                if (res.isConfirmed) {
                    axios.delete('user/deleteArticle/' + t.article.id).then(res => {
                        if (res.data.status == 1) {
                            t.back();
                            t.$destroy();
                        }
                    });

                }
            });
        }

    },
    data() {
        return {
            article: null
        };
    },
};
