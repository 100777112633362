import { render, staticRenderFns } from "./index.vue?vue&type=template&id=354bf391&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.css?vue&type=style&index=0&id=354bf391&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "354bf391",
  null
  
)

export default component.exports