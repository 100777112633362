
export default {

    created() {

        let t = this;

        t.loadData();

    },
    deactivated() {
        let t = this;
        if (t.iReadMsg != 0) {
            clearInterval(t.iReadMsg);
        }
    },
    activated() {
        let t = this;

        t.enterTimes++;

        if (t.enterTimes > 1) {

            let msgListEle = document.getElementById('msgList');

            if (msgListEle) {
                setTimeout(function () {
                    $(msgListEle).fadeIn();
                    msgListEle.scrollTop = msgListEle.scrollHeight;
                }, 100);
            }

            if (!t.inited) {
                t.loadData();
            }
            else {
                t.iReadMsg = setInterval(function () {
                    t.readMsg();
                }, t.intervalTime);
            }
        }
    },
    methods: {

        loadData() {

            let t = this;

            t.inited = true;

            let p = getURLParams();

            t.chatId = p.chat_id;

            axios.get('user/msg/' + t.chatId+'?is_group=1').then(res => {
                if (res.data.status == 1) {
                    t.user = res.data.options.user;
                    let msgList = document.getElementById('msgList');
                    $(msgList).hide();
                    t.list.msg = res.data.options.items;
                    let latestId = 0;
                    t.list.msg.forEach(msg => {
                        if (msg.receiver_id == t.U.id)
                            msg.read = 2;
                        if (msg.id > latestId)
                            latestId = msg.id;
                    });
                    t.latestMsgId = latestId;
                    t.iReadMsg = setInterval(function () {
                        t.readMsg();
                    }, t.intervalTime);
                    setTimeout(function () {
                        let msgListEle = document.getElementById('msgList');
                        $(msgListEle).fadeIn();
                        msgListEle.scrollTop = msgListEle.scrollHeight;
                    }, 100);

                }
            });


        },

        readMsg() {
            let t = this;

            axios.post('user/readNewMsg/' + t.chatId + '/' + t.latestMsgId, { 'unread_msg_ids': t.list.unreadMsgId.join(','), 'is_group': 1 }).then(res => {
                if (res.data.status == 1) {
                    if (res.data.options.items.length > 0) {
                        let msgList = res.data.options.items;
                        msgList.forEach(msg => {
                            msg.read = 2;
                            t.latestMsgId = msg.id;
                            t.list.msg.push(msg);
                        });

                        setTimeout(function () {
                            let msgListEle = document.getElementById('msgList');
                            msgListEle.scrollTop = msgListEle.scrollHeight;
                        }, 100);
                    }
                    let readItems = res.data.options.read_items;
                    if (readItems && readItems.length > 0) {
                        readItems.forEach(item => {
                            if (t.dict.msg[item.id]) {
                                t.dict.msg[item.id].read = item.read;
                                if (item.read == 2)
                                    t.list.unreadMsgId.remove(item.id);
                            }
                        });
                        t.$forceUpdate();
                    }

                }
            });

        },

        viewMedical() {
            this.$router.push('/emedical?id=' + this.user.id);
        },

        sendMsg() {

            let t = this;

            if (!t.f.content.trim()) {
                return;
            }

            axios.post('user/sendMsg/' + t.chatId, { 'content': t.f.content.trim(), 'read': 1,'last_sent_by':2 }).then(res => {
                if (res.data.status == 1) {
                    let msgItem = res.data.options.item;
                    t.list.msg.push(msgItem);
                    t.list.unreadMsgId.push(msgItem.id);
                    t.dict.msg[msgItem.id] = msgItem;
                    setTimeout(function () {
                        let msgListEle = document.getElementById('msgList');
                        msgListEle.scrollTop = msgListEle.scrollHeight;
                    }, 100);
                }
            });

            t.f.content = '';

        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                let msgItem = {
                    'sender_id': t.U.id,
                    'content': blobUrl,
                    'type': 2,
                    'created_at': (new Date()).format('yyyy/MM/dd hh:mm:ss'),
                    'read': 1
                };

                t.list.msg.push(msgItem);

                setTimeout(function () {
                    let msgListEle = document.getElementById('msgList');
                    msgListEle.scrollTop = msgListEle.scrollHeight;
                }, 100);

                let f = new FormData();

                f.append('image', file);

                axios.post('user/sendMsg/' + t.chatId, f).then(res => {
                    if (res.data.status == 1) {

                        delete res.data.options.item.content;
                        mirror(res.data.options.item, msgItem);

                        t.list.unreadMsgId.push(msgItem.id);
                        t.dict.msg[msgItem.id] = msgItem;
                    }
                });


            };

            ImageUploader.openSelector();
        },


    },
    data() {
        return {
            enterTimes: 0,
            intervalTime: 3000,
            iReadMsg: -1,
            latestMsgId: 0,
            inited: false,
            chatId: -1,
            user: null,
            f: {
                content: '',
            },
            dict: {
                msg: {}
            },
            list: {
                msg: [
                ],
                unreadMsgId: []
            },

        };
    },
    computed: {
    }
};
