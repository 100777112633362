
import AppNav from "./components/nav";
import { Dialog } from "vant";
import Vue from 'vue'
export default {
  name: "Index",
  components: {
    AppNav,
  },
  data() {
    return {
      showCategorySelector: false,
      dictCategory: {},
      categoryItems: [],
      activeId: -1,
      activeIndex: 0,
      disabledScroll: false,
      showWarningBox: false
    };
  },
  methods: {
    initData() {
      let t = this;
      axios.get('unit').then(res => {
        if (res.data.status == 1) {
          let units = res.data.options.items;
          let topCates = [];
          let dict = {};
          units.forEach(unit => {
            if (!unit.parent_id) {
              topCates.push(unit);
              unit.children = [{ text: '所有' + unit.name, id: -1 }];
            }
            dict[unit.id] = unit;
            unit.text = unit.name;
          });

          units.forEach(unit => {
            if (unit.parent_id) {
              dict[unit.parent_id].children.push(unit);
            }
          });
          t.dictCategory = dict;
          t.categoryItems = topCates;
        }
      });

    },
    goExpertList() {

      let t = this;

      t.showCategorySelector = false;

      if (t.activeId == -1) {
        t.$router.replace('/experts?unit_id=' + t.categoryItems[t.activeIndex].id);
      }
      else {
        t.$router.replace('/experts?unit_id=' + t.categoryItems[t.activeIndex].id + '&child_id=' + t.activeId);
      }

    },
    afterLogin() {
      let t = this;

      axios.defaults.headers.common["Token"] = this.U.token;
      axios.defaults.headers.common["OpenID"] = this.U.open_id;

      t.initSharing();

      Vue.prototype.mode = t.U.role;

      if (localStorage['override_mode'])
        Vue.prototype.mode = Number(localStorage['override_mode']);

      if (t.$router.currentRoute.name == 'select') {

      }
      else {

        if (t.mode == 1) {

          if (t.$router.currentRoute.name == 'euser') {
            t.$router.replace('/user');
          }

        }

        if (t.mode == 2) {
          if (t.$router.currentRoute.name == 'home') {
            t.$router.replace('/euser');
          }
        }
      }

      t.$forceUpdate();

      setTimeout(function () {
        t.refreshNav();
      }, 100);

    },
    cancel() {
      this.resolveFun(false);
      // this.showWarningBox = false;
      A.err('很抱歉，由于您未同意用户协议，小程序无法向您服务，请点击右上角退出。', '温馨提示');
    },
    confirm() {
      this.resolveFun(true);
      this.showWarningBox = false;
      sessionStorage['agreed'] = 1;
      // localStorage['agreed'] = 1;

      if (!localStorage['hint']) {
        A.show('如果您不知道应该如何咨询专家，请在首页顶部点击“快速咨询”。', '温馨提示', 'info', false);
        localStorage['hint'] = 1;
      }
    }
  },
  created() {

    let t = this;

    // if (/select/.test(t.$router.currentRoute.name))
    //   localStorage.clear();

    Vue.prototype.startUrl = location.href;
    Vue.prototype.busying = false;

    Vue.prototype.afterLogin = t.afterLogin;
    Vue.prototype.mode = localStorage['override_mode'] ? Number(localStorage['override_mode']) : 1;

    Dialog.setDefaultOptions({ confirmButtonText: '确认' });

    axios.defaults.baseURL = '//hlyzxyh.cn/v1/';

    // if (/localhost|dev/.test(location.host))
      // axios.defaults.baseURL = '//localhost/hlyzxyh/api/public/v1/';

    Vue.prototype.back = function () {
      this.$router.go(-1);
    }

    Vue.prototype.goChats = function () {
      this.$router.push('/chats');
    }

    Vue.prototype.warningAlert = () => {

      t.showWarningBox = true;

      const myPromise = new Promise((resolve, reject) => {
        t.resolveFun = resolve;
      });

      return myPromise;

    }

    Vue.prototype.pickCategory = function () {
      t.showCategorySelector = true;
    }

    // !localStorage['agreed'] &&
    if (/select|eprofile|euser/.test(t.$router.currentRoute.name)) {

    }
    else {
      if (!sessionStorage['agreed'])
        t.warningAlert();
    }

    Vue.prototype.U = {
      name: '',
      avatar_url: '',
      token: '',
      id: '',
      open_id: '',
    };

    if (S.get('_u')) {
      t.U = Vue.prototype.U = S.get('_u');
    }

    axios.defaults.headers.common["Token"] = Vue.prototype.U.token;
    axios.defaults.headers.common["OpenID"] = Vue.prototype.U.open_id;

    let p = getURLParams();

    let openID = p.open_id;

    if (!openID) {
      if (t.U && t.U.open_id)
        openID = t.U.open_id;
    }

    if (openID) {
      axios.post('account/signInByOpenId', { 'open_id': openID }).then(res => {
        if (res.data.status == 1) {
          let U = res.data.options.item;
          S.set('_u', U);
          if (U.token != t.U.token) {
            location.reload();
            return;
          }
          mirror(U, t.U);
          t.afterLogin();
          t.refreshNav();
        }
      });
    }

    t.initData();

    window._VERSION = '0.0.4';

    axios.get('version?d=' + (new Date()).valueOf()).then(res => {
      if (res.data.status == 1) {
        if (res.data.options.version != window._VERSION) {
          A.show('当前版本过低，点击确定重新加载。<br><br>如频繁看到本提示，请从最近使用的小程序中移除“校友咨询”或稍后再尝试。', '温馨提示', 'info', false).then(res => {
            localStorage.clear();
            location.replace(Vue.prototype.startUrl);
          });
        }
      }
    });


  },
  mounted() {
    let t = this;

  },

};
