
export default {
    name: 'chats',
    created() {

        let t = this;

        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {

            let t = this;

            axios.get('user/chat?m=user').then(res => {
                if (res.data.status == 1) {
                    t.list.chat = res.data.options.items;

                    t.stats.total = t.list.chat.length;

                    t.list.chat.forEach(chat => {

                        if (chat.count_unread > 0)
                            t.stats.unread++;

                    });

                    t.group = res.data.options.group;
                }
            });

        },

        goChat(item) {
            if (item.count_unread > 0) {
                this.stats.unread--;
                item.count_unread = 0;
            }
            this.$router.push('/chat?oid=' + item.other.id)
        },

        goGroup() {
            this.$router.push('/group');
        }
    },
    data() {
        return {
            group: null,
            search: {
                status: 'all',
                keywords: ''
            },
            stats: {
                total: 0,
                unread: 0
            },
            list: {
                chat: [
                ],
            },

        };
    },
    computed: {
        filteredChats: function () {
            var arr = [];
            var arr2 = this.list.chat;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.status != 'all' && item.count_unread < 1)
                    continue;
                if (this.search.keywords && item.other.name.indexOf(this.search.keywords) < 0)
                    continue;
                arr.push(item);
            }

            return arr;
        },
    }
};
