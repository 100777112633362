
import { areaList } from '@vant/area-data';
export default {

    created() {

        let t = this;

        t.loadData();

        if (t.U.id)
            mirror(t.U, t.f);

        if (!t.f.gender)
            t.f.gender = 1;

    },
    activated() {
        let t = this;

        let p = getURLParams();

        if (p.back == 1) {
            t.showBack = true;
        }
    },
    methods: {


        loadData() {
            let t = this;
            axios.get('unit').then(res => {
                if (res.data.status == 1) {
                let units = res.data.options.items;
                let topCates = [];
                let dict = {};
                units.forEach(unit => {
                    if (!unit.parent_id) {
                    topCates.push(unit);
                    unit.children = [{ text: unit.name + '[大科]', id: -1 }];
                    }
                    dict[unit.id] = unit;
                    unit.text = unit.name;
                });

                units.forEach(unit => {
                    if (unit.parent_id) {
                    dict[unit.parent_id].children.push(unit);
                    }
                });
                t.dictCategory = dict;
                t.categoryItems = topCates;
                }
            });

        },

        confirmCategory(){

            let t = this;

            if (t.activeId == -1) {
                t.f.unit_id = t.categoryItems[t.activeIndex].id;
            }
            else {
                t.f.unit_id = t.activeId;
            }
            t.showCategorySelector2 = false;
        },

        submit() {
            let t = this;
            let f = t.f;

            if (!f.name) {
                A.err('请输入姓名');
                return;
            }

            // if (!f.birthday) {
            //     A.err('请选择出生日期');
            //     return;
            // }

            // if (!f.city) {
            //     A.err('请选择所在城市');
            //     return;
            // }

            // if (!f.title) {
            //     A.err('请输入职称');
            //     return;
            // }

            // if (!f.year) {
            //     A.err('请输入从业年限');
            //     return;
            // }

            // if (!f.summary) {
            //     A.err('请输入专业擅长');
            //     return;
            // }

            // if (!f.desc) {
            //     A.err('请输入个人简介');
            //     return;
            // }

            if (t.busying)
                return;

            t.busying = true;

            function _save() {

                f.role = 2;

                let fields = ['name', 'city', 'unit_text', 'hospital', 'year', 'title', 'summary', 'desc','role','avatar_url','unit_id'];

                axios.post('user/saveProfile', getFields(f, fields)).then(res => {

                    t.busying = false;

                    if (res.data.status == 1) {
                        A.toast('保存成功');
                        mirror(res.data.options.item, t.U);
                        S.set('_u', t.U);
                        t.afterLogin();
                        t.$router.replace('/euser');
                    }
                });

            }

            if (t.hasNewAvatar) {

                let f = new FormData();

                f.append('image', t.mainImgBlobDict[t.f.avatar_url]);

                axios.post('user/saveImage', f).then(res => {
                    if (res.data.status == 1) {
                        t.f.avatar_url = res.data.options.img_url;
                        _save();
                    }
                });
            }
            else
                _save();
        },

        onSelectCity(options) {


            let arr = [options[0].name];
            if (options.length > 1)
                arr.push(options[1].name);

            arr = arr.unique();

            this.f.city = arr.join(',');
            this.showCitySelector = false;
        },

        onSelectDate(date) {
            this.f.birthday = date.format('yyyy/MM/dd');
            this.showDateSelector = false;
        },

        closeCropper() {
            $('.cropper-item').hide();
        },

        confirmCropper() {

            let t = this;

            t.cropper.getCroppedCanvas({
                minHeight: 200,
                minWdith: 200,
                maxHeight: 1000,
                maxWdith: 1000,
                fillColor: '#fff',
            }).toBlob((blob) => {

                let blobUrl = getObjectURL(blob);
                t.mainImgBlobDict[blobUrl] = blob;
                t.hasNewAvatar = true;
                t.f.avatar_url = blobUrl;
                t.closeCropper();

            });
        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                const image = document.getElementById('cropperImg');

                image.src = blobUrl;

                if (t.cropper) {
                    t.cropper.destroy();
                    t.cropper = null;
                }

                image.onload = () => {
                    t.cropper = new Cropper(image, {
                        aspectRatio: 1,
                        autoCropArea: 1,
                        crop(event) {
                        },
                    });
                    $('.cropper-item').show();
                }

                event.target['value'] = '';


            };

            ImageUploader.openSelector();
        },
    },
    data() {
        return {
            hasNewAvatar: false,
            showBack: false,
            list: {
                unit: [],
            },
            currentDate: new Date(),
            minDate: new Date('1940/01/01'),
            maxDate: new Date(),
            showCitySelector: false,
            showDateSelector: false,
            f: {
                name: '',
                gender: 1,
                city: '',
                title: '',
                summary: '',
                desc: '',
                year: '',
                avatar_url: '',
                unit_id:''
            },
            areaList: areaList,
            mainImgBlobDict: {},

            cropper: null,

            showCategorySelector2:false,
            dictCategory: {},
            categoryItems: [],
            activeId: -1,
            activeIndex: 0,
        };
    },
};
