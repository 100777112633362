

export default {
  name: "Loader",
  props: {
    pager: Object
  },
  mounted() {

  },
  created() {
    let t = this;

    let p = screen.availHeight * 2;

    window.addEventListener('scroll', function () {
      let y = t.$el.getBoundingClientRect().y;
      if (t.pager.enabled && y < p) {
        if (!t.pager.loading) {
          if (t.pager.index + 1 <= t.pager.pageTotal) {
            t.pager.index++;
            t.pager.load();
          }
        }
      }
    });

  },
  methods: {
  },
  data() {
    return {
    };
  },
};
