import Vue from 'vue'
import Router from 'vue-router'

import Home from './home/home'
import Profile from './profile/profile'
import Experts from './experts/experts'
import Expert from './expert/expert'
import Medical from './medical/medical'
import User from './user/user'
import Articles from './articles/articles'
import Article from './article/article'
import QA from './qa/qa'
import Chats from './chats/chats'
import EChats from './chats/echats'
import Chat from './chat/chat'
import DChat from './chat/dchat'
import EProfile from './eprofile/eprofile'
import EUser from './euser/euser'
import GetVip from './getvip/getvip'
import EArticles from './earticles/earticles'
import EMedical from './emedical/emedical'
import Duty from './chats/duty'
import Index from './index/index'

import Select from './select/select'
import Group from './group/group'
import EGroup from './group/egroup'

import NewArticle from './newarticle/newarticle'
import './style.css'

function pickHome() {
  return Home;
}

Vue.use(Router)
export default new Router({
  base: '/',
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      window.lastPosition = savedPosition;
      return savedPosition
    } else {
      window.lastPosition = {
        x: 0,
        y: 0
      };
      return {
        x: 0,
        y: 0
      }
    }
  },
  routes: [

    //以下路由用于扉页创建，备案过审
    // {
    //   path: '',
    //   redirect: '/index'
    // },
    {
      name: 'article',
      path: '/article',
      component: Article,
    },
    // {
    //   name: 'index',
    //   path: '/index',
    //   component: Index,
    // },
    //以上路由用于扉页创建，备案过审

    {
      path: '',
      redirect: '/home'
    },
    {
      name: 'home',
      path: '/home',
      component: pickHome(),
    },
    // {
    //   name: 'profile',
    //   path: '/profile',
    //   component: Profile,
    // },
    {
      name: 'experts',
      path: '/experts',
      component: Experts,
    },
    {
      name: 'expert',
      path: '/expert',
      component: Expert,
    },
    {
      name: 'medical',
      path: '/medical',
      component: Medical,
    },
    {
      name: 'user',
      path: '/user',
      component: User,
    },
    {
      name: 'articles',
      path: '/articles',
      component: Articles,
    },
    {
      name: 'qa',
      path: '/qa',
      component: QA,
    },
    {
      name: 'chats',
      path: '/chats',
      component: Chats,
    },
    {
      name: 'duty',
      path: '/duty',
      component: Duty,
    },
    {
      name: 'echats',
      path: '/echats',
      component: EChats,
    },
    {
      name: 'eprofile',
      path: '/eprofile',
      component: EProfile,
    },
    {
      name: 'euser',
      path: '/euser',
      component: EUser,
    },
    {
      name: 'chat',
      path: '/chat',
      component: Chat
    },
    {
      name: 'dchat',
      path: '/dchat',
      component: DChat
    },
    {
      name: 'getvip',
      path: '/getvip',
      component: GetVip
    },
    {
      name: 'getvip',
      path: '/getvip',
      component: GetVip
    },
    {
      name: 'earticles',
      path: '/earticles',
      component: EArticles,
    },
    {
      name: 'newarticle',
      path: '/newarticle',
      component: NewArticle,
    },
    {
      name: 'emedical',
      path: '/emedical',
      component: EMedical,
    },
    {
      name: 'select',
      path: '/select',
      component: Select,
    },
    {
      name: 'group',
      path: '/group',
      component: Group,
    },
    {
      name: 'egroup',
      path: '/egroup',
      component: EGroup,
    },
  ],
})