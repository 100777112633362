
export default {

    created() {
    },

    activated() {
        let t = this;
        t.loadData();
    },
    methods: {

        loadData() {
            let t = this;

            t.stats.owned = 0;

            axios.get('article').then(res => {
                if (res.data.status == 1) {
                    let items = res.data.options.items;

                    t.stats.total = items.length;
                    items.forEach(item => {
                        if (item.user_id == t.U.id)
                            t.stats.owned++;
                    });
                    t.list.article = items;
                }
            });

        },

        goArticle(item) {
            this.$router.push('/article?id=' + item.id)
        },

        goPublish() {
            this.$router.push('newarticle')
        }
    },
    data() {
        return {
            stats: {
                total: 0,
                owned: 0,
            },
            search: {
                status: 'all',
                keywords: ''
            },
            list: {
                article: [
                ],
            },

        };
    },
    computed: {
        filteredArticles: function () {
            var arr = [];
            var arr2 = this.list.article;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.keywords && item.title.indexOf(this.search.keywords) < 0)
                    continue;
                if (this.search.status == 1 && item.user_id != this.U.id)
                    continue;
                arr.push(item);
            }

            return arr;
        }
    }
};
