
import { ImagePreview } from 'vant';

export default {

    created() {

        let t = this;


        let p = getURLParams();

        if (!p.id) {
            t.back();
        }

        t.loadData(p.id);

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData(userId) {

            let t = this;

            axios.post('user/getMedicalProfile/' + userId).then(res => {
                if (res.data.status == 1) {
                    let user = res.data.options.item;
                    if (user.img_urls)
                        user.img_urls_arr = user.img_urls.split(',');
                    t.user = user;

                }
            });

        },
        showPreview(idx) {

            let t = this;
            if (t.user.img_urls_arr && t.user.img_urls_arr.length > 0) {
                let srcArr = [];

                t.user.img_urls_arr.forEach(src => {
                    srcArr.push(t.url(src));
                });

                ImagePreview({
                    images: srcArr,
                    startPosition: idx,
                    closeable: true,
                });
            }


        }
    },
    data() {
        return {
            user: null

        };
    },
};
