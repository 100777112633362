

export default {


    created() {

        let t = this;

    },
    activated() {
        let t = this;

        let d = new Date();

        let h = d.getHours();

        if (h < 6) {
            t.timeStr = '晚上好';
        }
        else if (h >= 6 && h < 11) {
            t.timeStr = '上午好';
        }
        else if (h >= 11 && h < 13) {
            t.timeStr = '中午好';
        }
        else if (h >= 13 && h < 19) {
            t.timeStr = '下午好';
        }
        else if (h >= 19) {
            t.timeStr = '晚上好';
        }

        t.loadData();
    },
    methods: {

        loadData() {
            let t = this;

            axios.get('getData4HomePage').then(res => {
                if (res.data.status == 1) {
                    t.list.article = res.data.options.articles;
                    t.list.expert = res.data.options.experts;
                    if (res.data.options.chat) {
                        let chats = [res.data.options.chat];

                        chats.forEach(chat => {

                            if (chat.latest_msg) {
                                let d = new Date();
                                let d2 = new Date(chat.latest_msg.created_at);
                                if (chat.latest_msg.sender_id == t.U.id)
                                    chat.status_text = t.getElapsedTimeString(d2, d) + "前咨询了他";
                                else
                                    chat.status_text = t.getElapsedTimeString(d2, d) + "前回复了你";
                            }
                            else {
                                let d = new Date();
                                let d2 = new Date(chat.created_at);
                                chat.status_text = t.getElapsedTimeString(d2, d) + "前创建了会话";
                            }
                        });
                        t.list.chat = chats;
                    }
                }
            });

        },

        goExperts(){
            this.$router.replace('/experts');
        },

        quickTalk(){
            this.$router.push('/group');
        }
    },
    data() {
        return {

  
            timeStr: '',

            list: {
                article: [
                ],
                unit: [
                ],
                chat: [
                ],
                expert: [
                ]
            },

        };
    },
};
