
import Vue from 'vue'
export default {
  name: "Nav",
  props: {},
  mounted() { },
  created() {

    let t = this;

    Vue.prototype.refreshNav = function () {

      let reg;
      let reg1 = /\/(home|experts|chats|user)/i
      let reg2 = /\/(duty|echats|earticles|euser)/i;

      if (t.mode == 1)
        reg = reg1;
      else
        reg = reg2;

      t.$router.beforeEach((to, from, next) => {

        let r = reg.exec(to.path);

        if (r && r.length > 0) {
          t.page = r[1];
          t.showNav = true;
        }
        else
          t.showNav = false;

        if (!t.showNav) {
          if (reg1.test(to.path)) {
            t.mode = 1;
            localStorage['override_mode'] = 1;
            t.showNav = true;
          }
          else if (reg2.test(to.path)) {
            t.mode = 2;
            localStorage['override_mode'] = 2;
            t.showNav = true;
          }
        }

        next();
      });

      let r = reg.exec(location.pathname);

      if (r && r.length > 0) {
        t.page = r[1];
        t.showNav = true;
      }
      else
        t.showNav = false;

      if (!t.showNav) {
        if (reg1.test(location.pathname)) {
          t.mode = 1;
          localStorage['override_mode'] = 1;
          t.showNav = true;
        }
        else if (reg2.test(location.pathname)) {
          t.mode = 2;
          localStorage['override_mode'] = 2;
          t.showNav = true;
        }
      }

    }
    t.refreshNav();

  },
  methods: {

    go(page) {

      if (page == this.page) return;

      this.page = page;

      this.$router.replace(page);

    },

  },
  data() {
    return {
      resolveFun: null,
      showQuickEntrances: false,
      page: "",
      showNav: false,
    };
  },
  components: {
  },
};
