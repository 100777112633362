
export default {

    created() {

        let t = this;

        t.go2();

        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;
        },
        go1() {
            localStorage['override_mode'] = 1;
            if (!sessionStorage['agreed'])
                this.warningAlert();
            this.$router.replace('/home');
        },
        go2() {
            localStorage['override_mode'] = 2;
            if (!this.U.name)
                this.$router.replace('/eprofile');
            else
                this.$router.replace('/euser');
        }
    },
    data() {
        return {

        };
    },
    computed: {
    }
};
