
export default {

    created() {

        let t = this;

        t.loadData();

    },
    activated() {
        let t = this;
    },
    methods: {

        loadData() {
            let t = this;

            axios.get('qa').then(res => {
                if (res.data.status == 1) {
                    let items = res.data.options.items;
                    items.forEach(item => {
                        item.expanded = false;
                    })
                    t.list.qa = items;
                }
            });

        },
    },
    data() {
        return {
            search: {
                keywords: ''
            },
            list: {
                qa: [
                ],
            },

        };
    },
    computed: {
        filteredQA: function () {
            var arr = [];
            var arr2 = this.list.qa;

            for (var i = 0; i < arr2.length; i++) {
                var item = arr2[i];
                if (this.search.keywords && item.title.indexOf(this.search.keywords) < 0)
                    continue;
                arr.push(item);
            }

            return arr;
        }
    }
};
