
export default {

    name: 'group',

    created() {

        let t = this;

        if (t.mode == 1 && t.U.vip != 2) {

            t.back();

            Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-green',
                    cancelButton: 'btn btn-dark mr10'
                },
                confirmButtonText: '去绑定',
                cancelButtonText: '取消',
                buttonsStyling: false,
                showCancelButton: true
            }).fire('温馨提示', '咨询功能目前仅面向校友会成员开放，您可以通过输入手机号绑定会员。', 'info').then(res => {
                if (res.isConfirmed) 
                    t.$router.push('/getvip');
                    
            });

            return;
        }

        t.ready = true;
        if (!t.U.desc && !t.U.img_urls && !t.U.unit_text && localStorage['skip_medical'] != 1) {
            B.medicalPage = {
                nextPage: t.$router.currentRoute.fullPath
            };
            t.$router.replace('/medical');
            t.$destroy();
            return;
        }
        else
            t.loadData();

    },
    deactivated() {

        let t = this;
        if (t.iReadMsg != 0) {
            clearInterval(t.iReadMsg);
        }
    },
    activated() {
        let t = this;

        t.enterTimes++;

        if (t.enterTimes > 1) {

            let msgListEle = document.getElementById('msgList');

            if (msgListEle) {
                setTimeout(function () {
                    $(msgListEle).fadeIn();
                    msgListEle.scrollTop = msgListEle.scrollHeight;
                }, 100);
            }

            if (!t.inited) {
                t.loadData();
            }
            else {
                t.iReadMsg = setInterval(function () {
                    t.readMsg();
                }, t.intervalTime);
            }
        }
    },
    methods: {

        loadData() {

            let t = this;

            t.inited = true;

            axios.get('user/getGroupChatId').then(res => {
                if (res.data.status == 1) {
                    t.chatId = res.data.options.chat_id;

                    axios.get('user/msg/' + t.chatId).then(res => {
                        if (res.data.status == 1) {
                            let msgList = document.getElementById('msgList');
                            $(msgList).hide();
                            t.list.msg = res.data.options.items;
                            let latestId = 0;
                            t.list.msg.forEach(msg => {
                                if (msg.receiver_id == t.U.id || !msg.receiver_id)
                                    msg.read = 2;
                                if (msg.id > latestId)
                                    latestId = msg.id;
                            });
                            t.latestMsgId = latestId;
                            t.iReadMsg = setInterval(function () {
                                t.readMsg();
                            }, t.intervalTime);
                            setTimeout(function () {
                                let msgListEle = document.getElementById('msgList');
                                $(msgListEle).fadeIn();
                                msgListEle.scrollTop = msgListEle.scrollHeight;
                            }, 100);

                            axios.get('user/clearUnread/' + t.chatId + '?is_group=1').then(res => {
                                if (res.data.status == 1) {

                                }
                            });

                        }
                    });

                }
            });

        },

        readMsg() {
            let t = this;

            axios.post('user/readNewMsg/' + t.chatId + '/' + t.latestMsgId, { 'unread_msg_ids': t.list.unreadMsgId.join(','), 'is_group': 1 }).then(res => {
                if (res.data.status == 1) {
                    if (res.data.options.items.length > 0) {
                        let msgList = res.data.options.items;
                        msgList.forEach(msg => {
                            msg.read = 2;
                            t.latestMsgId = msg.id;
                            t.list.msg.push(msg);
                        });

                        setTimeout(function () {
                            let msgListEle = document.getElementById('msgList');
                            msgListEle.scrollTop = msgListEle.scrollHeight;
                        }, 100);
                    }
                    let readItems = res.data.options.read_items;
                    if (readItems && readItems.length > 0) {
                        readItems.forEach(item => {
                            if (t.dict.msg[item.id]) {
                                t.dict.msg[item.id].read = item.read;
                                if (item.read == 2)
                                    t.list.unreadMsgId.remove(item.id);
                            }
                        });
                        t.$forceUpdate();
                    }

                }
            });

        },

        sendMsg() {

            let t = this;

            if (!t.f.content.trim()) {
                return;
            }

            axios.post('user/sendMsg/' + t.chatId, { 'content': t.f.content.trim(), 'read': 2, 'last_sent_by': 1 }).then(res => {
                if (res.data.status == 1) {
                    let msgItem = res.data.options.item;
                    t.list.msg.push(msgItem);
                    t.list.unreadMsgId.push(msgItem.id);
                    t.dict.msg[msgItem.id] = msgItem;
                    setTimeout(function () {
                        let msgListEle = document.getElementById('msgList');
                        msgListEle.scrollTop = msgListEle.scrollHeight;
                    }, 100);
                }
            });

            t.f.content = '';

        },

        uploadPicture() {

            let t = this;

            ImageUploader.onSelectImage = (file) => {

                file = event.target['files'][0];

                var blobUrl = getObjectURL(file);

                let msgItem = {
                    'sender_id': t.U.id,
                    'content': blobUrl,
                    'type': 2,
                    'created_at': (new Date()).format('yyyy/MM/dd hh:mm:ss'),
                    'read': 1
                };

                t.list.msg.push(msgItem);

                setTimeout(function () {
                    let msgListEle = document.getElementById('msgList');
                    msgListEle.scrollTop = msgListEle.scrollHeight;
                }, 100);

                let f = new FormData();

                f.append('image', file);

                axios.post('user/sendMsg/' + t.chatId, f).then(res => {
                    if (res.data.status == 1) {

                        delete res.data.options.item.content;
                        mirror(res.data.options.item, msgItem);

                        t.list.unreadMsgId.push(msgItem.id);
                        t.dict.msg[msgItem.id] = msgItem;
                    }
                });


            };

            ImageUploader.openSelector();
        },


    },
    data() {
        return {
            ready:false,
            enterTimes: 0,
            intervalTime: 3000,
            iReadMsg: -1,
            latestMsgId: 0,
            inited: false,
            chatId: -1,
            f: {
                content: '',
            },
            dict: {
                msg: {}
            },
            list: {
                msg: [
                ],
                unreadMsgId: []
            },

        };
    },
    computed: {
    }
};
